<script>
    import {
        Button,
        Modal,
        P,
        Spinner,
        Table,
        TableBody,
        TableBodyCell,
        TableBodyRow,
        TableHead,
        TableHeadCell,
    } from "flowbite-svelte";
    import { EyeSolid, TrashBinSolid } from "flowbite-svelte-icons";
    import {
        deleteFile,
        get_file_summary
    } from "../service/http_calls";
    import {
        file_content,
        file_summary_content,
        files_list,
        selected_file_id,
    } from "../service/store";
    import FileUploader from "./FileUploader.svelte";

    import { marked } from "marked";
    export let module;

    let loading = false;
    let file_content_modal_open = false;

    let file_text_edit = false;
    let loading_delete = false;
    console.log(`Module is ${module}`);
    let selectedFileName = $files_list[module]?.find(
        (obj) => obj.id == $selected_file_id,
    )?.name;

    // Whenever the file selection or file list changes, update the local variable
    $: selectedFileName = $files_list[module]?.find(
        (obj) => obj.id == $selected_file_id,
    )?.name;

    let current_files = [];
    $: current_files = $files_list[module];

    let selected_file_id_to_delete;
    let selected_file_id_to_summary;


    const handleDeleteFile = async (file_id) => {
        selected_file_id_to_delete = file_id;

        console.log("deleting " + file_id);
        loading_delete = true;

        files_list.update(prev => {
            prev[module]  = prev[module].filter(item => item.id!=file_id);
            return prev;
        });


        await deleteFile(file_id, module);
        // let resp = await fetchFiles(module);

        // files_list.update((prev) => {
        //     if (Array.isArray(resp)){
        //         prev[module] = resp;
        //     }
        //     return prev;
        // });

        loading_delete = false;
    };
    let loading_file_summary = false;
    async function handleFileSummary(file_id) {
        loading_file_summary = true;
        selected_file_id_to_summary = file_id;
        let resp = await get_file_summary(file_id);
        file_summary_content.set(resp.formatted_content);
        loading_file_summary = false;
        selected_file_id.set(file_id);
        file_text_edit = true;
    }

    import ProgressBar from "@okrad/svelte-progressbar";
</script>

<div>
    <FileUploader {module}></FileUploader>

    <Table striped={true} class="w-full table-fixed mt-5">
        <TableHead
            class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400"
        >
            <TableHeadCell>File name</TableHeadCell>
            <TableHeadCell class="w-1/6 text-right">Status</TableHeadCell>
            <TableHeadCell class="w-1/6 text-right">Content</TableHeadCell>
            <TableHeadCell class="w-1/6 text-right">Summary</TableHeadCell>
            <TableHeadCell class="w-1/6 text-right">Delete</TableHeadCell>
        </TableHead>
        <TableBody tableBodyClass="divide-y">
            {#each current_files as file}
                <TableBodyRow>
                    <TableBodyCell
                        class="truncate overflow-hidden text-ellipsis"
                        >{file.name}</TableBodyCell
                    >
                    <TableBodyCell
                        class="w-1/6 text-right justify-end items-end"
                    >
                        {#if file.upload}
                            <ProgressBar valueLabel="Uploading"  series={[
                                {
                                    perc: file.upload,
                                    color: '#abeda4'
                                }
                            ]}></ProgressBar>
                        {:else}
                            <P class="text-right">{file.status}</P>
                        {/if}
                    </TableBodyCell>

                    <TableBodyCell class="w-1/6 text-right">
                        {#if file.status === "Ready!"}

                        <Button
                            outline={true}
                            class="!p-2"
                            size="xs"
                            color="purple"
                            on:click={() => {
                                file_content.set(file.content);
                                file_content_modal_open = true;
                            }}
                        >
                            <EyeSolid class="" />
                        </Button>
                        {/if}
                    </TableBodyCell>

                    <TableBodyCell class="w-1/6 text-right">
                        {#if file.status === "Ready!"}
                            {#if loading_file_summary && selected_file_id_to_summary == file.id}
                                <div>
                                    <Spinner></Spinner>
                                </div>
                            {:else}
                                <Button
                                    outline={true}
                                    class="!p-2"
                                    size="xs"
                                    color="purple"
                                    on:click={() => {
                                        handleFileSummary(file.id);
                                    }}
                                >
                                    <EyeSolid class="" />
                                </Button>
                            {/if}
                        {:else}
                            <P class="text-right">Loading...</P>
                        {/if}
                    </TableBodyCell>
                    <TableBodyCell class="w-1/6 text-right">
                        {#if loading_delete && selected_file_id_to_delete == file.id}
                            <Spinner></Spinner>
                        {:else}
                            <Button
                                outline={true}
                                class="!p-2"
                                size="xs"
                                color="red"
                                on:click={() => {
                                    handleDeleteFile(file.id);
                                }}
                            >
                                <TrashBinSolid class="" />
                            </Button>
                        {/if}
                    </TableBodyCell>
                </TableBodyRow>
            {/each}
        </TableBody>
    </Table>
</div>

<Modal bind:title={selectedFileName} bind:open={file_text_edit} autoclose>
    <div class="max-w-full prose">
        {@html marked($file_summary_content)}
    </div>
</Modal>
<Modal
    bind:title={selectedFileName}
    bind:open={file_content_modal_open}
    autoclose
>
    <div class="max-w-full">
        {$file_content}
    </div>
</Modal>
