<!-- src/routes/Pricing.svelte -->
<script>
  import { onMount, onDestroy } from "svelte";
  import { authenticated_user, current_user } from "../service/store";
  import { Spinner } from "flowbite-svelte";
  const BASE_URL = import.meta.env.VITE_BASE_URL;
  let loading = true;
  onMount(async () => {
    console.log("stripe customer is " + $current_user.strip_customer);
    if ($current_user.strip_customer != null) {
      console.log("non-empty");
      window.location.href = BASE_URL + "/payments/billing_portal";
    } else {
      console.log("empty");
      loading = false;
    }
  });
</script>

<svelte:head>
  <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
</svelte:head>

<div class="pricing-page">
  {#if loading}
    <Spinner></Spinner>
  {:else}
    <h1>Our Pricing</h1>
    <stripe-pricing-table
      pricing-table-id="prctbl_1PyXRMAnHQme9GY6HberkF9q"
      publishable-key="pk_test_51PyTlIAnHQme9GY6hMadhUAhrdpI6jzqXhIEcjYIqw6tURWLDicO4cW8GFbYvzfV22Z3XoVD9Pt5Ec2fc8KjDVAd00djAd4VRs"
      customer-email={$authenticated_user?.identity?.traits?.email}
    >
    </stripe-pricing-table>
  {/if}
</div>

<style>
  .pricing-page {
    text-align: center;
    padding: 50px;
  }

  .pricing-page h1 {
    margin-bottom: 30px;
  }

  .pricing-page p {
    font-size: 1.2em;
    color: #666;
  }
</style>
