<script>
  import { SvelteToast, toast } from "@zerodevx/svelte-toast";
  import { onMount } from "svelte";
  import Drafting from "./lib/Drafting.svelte";
  import EarlyAccess from "./lib/EarlyAccess.svelte";
  import Footer from "./lib/Footer.svelte";
  import Nav from "./lib/Nav.svelte";
  import Profile from "./lib/Profile.svelte";
  import Top from "./lib/Top.svelte";
  import Research from "./lib/Research.svelte";
  import Learn from "./lib/Learn.svelte";
  import Qualify from "./lib/Qualify.svelte";
  import Review from "./lib/Review.svelte";
  import Collaborate from "./lib/Collaborate.svelte";
  import Defend from "./lib/Defend.svelte";
  import Subscriptions from "./lib/Subscriptions.svelte";
  import Success from "./lib/Success.svelte";
  import Terms from "./lib/Terms.svelte";

  import {
    authenticated_user,
    current_user,
    files_list,
    questions,
    result_informed,
    terminal_messages,
  } from "./service/store";
  import SupportChat from "./lib/SupportChat.svelte";
  import { fetchFiles, get_result, getUser } from "./service/http_calls";
  import { P, Spinner, Card } from "flowbite-svelte";
  import GrantQualify from "./lib/GrantQualify.svelte";
  import GrantDrafting from "./lib/GrantDrafting.svelte";
  import GrantReview from "./lib/GrantReview.svelte";
  import LoginComponent from "./lib/LoginComponent.svelte";
  import SignUpComponent from "./lib/SignUpComponent.svelte";

  import CookieConsent from "./lib/CookieConsenter.svelte";
  let slug = "";

  let socket;
  const WS_BASE_URL = import.meta.env.VITE_WS_BASE_URL;
  let isOpen = false;
  let page_loading = false;

  // Initialize WebSocket connection
  function connectWebSocket() {
    const url = `${WS_BASE_URL}/ws`;
    console.log(`Attempting to connect to ${url}`);
    socket = new WebSocket(url);

    socket.onopen = () => {
      console.log("Connected to WebSocket");
      isOpen = true;
    };

    socket.onmessage = async (event) => {
      let parsed_payload = JSON.parse(event.data);

      if (parsed_payload.type === "error") {
        toast.push(parsed_payload.message, { classes: ["warn"] });
      }

      // if (parsed_payload.type != "command") {
      terminal_messages.set([...$terminal_messages, parsed_payload]);
      // }

      if (parsed_payload.type === "command") {
        if (parsed_payload.message.function === "get_files") {
          const resp = await fetchFiles(parsed_payload.message.module);

          let existing_files_list = $files_list[parsed_payload.message.module];
          let new_files_list = [];
          if (Array.isArray(resp)) {
            for (let i = 0; i < existing_files_list.length; i++) {
              let f = existing_files_list[i];
              let exists_in_new = resp.find((obj) => obj.name === f.name);
              if (typeof exists_in_new !== "undefined") {
                new_files_list.push(exists_in_new);
              } else {
                new_files_list.push(f);
              }
            }
          }

          files_list.update((prev) => {
            prev[parsed_payload.message.module] = new_files_list;
            return prev;
          });
          console.log("Got files for " + parsed_payload.message.module);
        } else if (parsed_payload.message.function === "get_result") {
          const module = parsed_payload.message.module;
          const question = parsed_payload.message.question;
          const result_id = parsed_payload.message.result_id;

          let res = await get_result(result_id);
          if (res.status === "done") {
            if ($result_informed[res.id] != true) {
              toast.push(`Question ${question} done`);
            }
            result_informed.update((prev) => {
              prev[res.id] = true;
              return prev;
            });
          }
          questions.update((prev) => {
            prev[question].response = res;
            return prev;
          });
        }
      }
    };

    socket.onclose = async () => {
      console.log("Disconnected from WebSocket");
      isOpen = false;
      page_loading = false;
      toast.push("Websocket disconnected, please refresh the page!");
      await authenticate();

      // sessionStorage.setItem("isOpen", "false"); // Mark as closed
    };

    socket.onerror = async (error) => {
      console.error("WebSocket error:", error);
      // toast.push("Websocket error, please refresh the page!");
      page_loading = false;
    };
  }
  onMount(async () => {
    if (!localStorage.getItem("isCleared")) {
      localStorage.clear();
      localStorage.setItem("isCleared", "true");
    }

    // Extracting the slug from the URL
    const pathParts = window.location.pathname.split("/").filter(Boolean);
    if (pathParts.length > 0) {
      slug = pathParts[pathParts.length - 1];
      console.log("You are at " + slug);
    } else {
      console.log("You are home page!");
    }

    // if ($current_user) {
    //   userId = $current_user.id;
    // } else {
    if (
      slug != "login" &&
      slug != "signup" &&
      slug != "early-access" &&
      slug != "terms"
    ) {
      page_loading = true;
      console.log("will try to authenticate...");
      await authenticate();
    }
    // }
    if (!isOpen && $current_user) {
      connectWebSocket();
    }
  });

  async function authenticate() {
    current_user.set(null);
    let auth_resp = await getUser();
    page_loading = false;

    console.log(auth_resp.id);
    if (typeof auth_resp?.id === "undefined") {
      console.log("should redirect right?");
      if (slug != "login" && slug != "signup") {
        window.location.href = "/login";
      }
    } else {
      current_user.set(auth_resp);
      authenticated_user.set(auth_resp);
    }
  }
</script>

<Nav></Nav>

{#if page_loading}
  <div class="flex justify-center items-center mt-10">
    <div class="flex flex-col items-center">
      <Spinner class="my-10"></Spinner>
      <P weight="bold">Loading, please wait...</P>
    </div>
  </div>
{:else if slug === ""}
  <Top></Top>
{:else if slug == "drafting"}
  <Drafting></Drafting>
{:else if slug == "early-access"}
  <EarlyAccess></EarlyAccess>
{:else if slug == "profile"}
  <Profile></Profile>
{:else if slug == "research"}
  <Research></Research>
{:else if slug == "learn"}
  <Learn></Learn>
{:else if slug == "qualify"}
  <Qualify></Qualify>
{:else if slug == "review"}
  <Review></Review>
{:else if slug == "collaborate"}
  <Collaborate></Collaborate>
{:else if slug == "defend"}
  <Defend></Defend>
{:else if slug == "subscriptions"}
  <Subscriptions></Subscriptions>
{:else if slug == "success"}
  <Success></Success>
{:else if slug == "grant-qualify"}
  <GrantQualify></GrantQualify>
{:else if slug == "grant-draft"}
  <GrantDrafting></GrantDrafting>
{:else if slug == "grant-review"}
  <GrantReview></GrantReview>
{:else if slug == "login"}
  <LoginComponent></LoginComponent>
{:else if slug == "signup"}
  <SignUpComponent></SignUpComponent>
{:else if slug == "terms"}
  <div class="flex justify-center items-center mt-10">
  <Card size="xl" class="m-40 p-10">
    <Terms />
  </Card>
</div>
{/if}

<SvelteToast options={{ classes: ["log"] }} />

{#if $current_user}
  <SupportChat />
{/if}

<CookieConsent
  allowButtonText={"Accept"}
  closeText={"Close"}
  descriptionText={"We are using cookies to improve your experience  "}
  cookiePolicyText={"Learn More"}
  cookiePolicyUrl={"https://smartclaim.uk/terms"}
  bgColor={"bg-gray-200"}
  buttonTextColor={"text-white"}
  closeTextColor={"text-purple-500"}
  buttonBgColor={"bg-purple-500"}
  descriptionTextColor={"text-gray-600"}
/>

<Footer></Footer>

<style>
  :global(.log.info) {
    --toastBackground: green;
  }
  :global(.log.warn) {
    --toastBackground: red;
  }
</style>
