<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Spinner,
        Textarea,
    } from "flowbite-svelte";
    import { BadgeCheckOutline, FlagOutline } from "flowbite-svelte-icons";
    import { onMount } from "svelte";
    import { ask_llm, fetchFiles, getUser } from "../service/http_calls";
    import { files_list, review } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    import { marked } from "marked";
    import { toast } from "@zerodevx/svelte-toast";

    onMount(async () => {
        const resp = await fetchFiles("review");

        files_list.update((prev) => {
            prev["review"] = resp;
            return prev;
        });
    });

    let loading_review = false;

    const submitReview = async () => {
        loading_review = true;

        console.log("submitReview");
        // handling coherence
        const resp_coherence = await ask_llm(
            $review.user_instructions.baseline + "\n" +
                $review.user_instructions.advance + "\n" +
                $review.user_instructions.uncertainty + "\n" +
                $review.user_instructions.resolution,
            "review",
            "overall",
            "coherence",
            "gpt-4o",
        );
        review.update((prev) => {
            prev["overall"]["coherence"]["response"] = resp_coherence.generated_content;
            return prev;
        });
        // handling risk factors
        const resp_risk_factors = await ask_llm(
            $review.user_instructions.baseline + "\n" +
                $review.user_instructions.advance + "\n" +
                $review.user_instructions.uncertainty + "\n" +
                $review.user_instructions.resolution,
            "review",
            "overall",
            "risk_factors",
            "gpt-4o",
        );
        review.update((prev) => {
            prev["overall"]["risk_factors"]["response"] = resp_risk_factors.generated_content;
            return prev;
        }); 
        toast.push("Overall Review Done")

        loading_review = false;
    };
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <P size="lg" class="my-5"><Span gradient>Baseline</Span></P>
            <Textarea
                bind:value={$review.user_instructions.baseline}
                rows={10}
                placeholder="Enter Technical Narrative Draft Here..."
            ></Textarea>
            <P size="lg" class="my-5"><Span gradient>Advance</Span></P>
            <Textarea bind:value={$review.user_instructions.advance} rows={10} placeholder=""
            ></Textarea>
            <P size="lg" class="my-5"><Span gradient>Uncertainty</Span></P>
            <Textarea bind:value={$review.user_instructions.uncertainty} rows={10} placeholder=""
            ></Textarea>
            <P size="lg" class="my-5"><Span gradient>Resolution</Span></P>
            <Textarea bind:value={$review.user_instructions.resolution} rows={10} placeholder=""
            ></Textarea>

            <div class="flex justify-end mt-5">
                {#if loading_review}
                    <div class="flex items-center mr-5">
                        <Spinner></Spinner>
                        <P>Submitting Review...</P>
                    </div>
                {:else}
                    <Button color="purple" on:click={() => submitReview()}
                        >Submit</Button
                    >
                {/if}
            </div>

            <P size="lg" class="my-5"><Span gradient>Overall</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Coherence </span
                    >
                    <div class="prose prose-sm">
                        {@html marked($review.overall.coherence.response)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Risk factors (if applicable)</span
                    >
                    <div class="prose prose-sm">
                        {@html marked($review.overall.risk_factors.response)}
                    </div>
                </AccordionItem>
            </Accordion>

            <P size="lg" class="my-5"><Span gradient>Baseline</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Baseline Statement</span
                    >
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Interenet Research</span
                    >
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Baseline Research Output</span
                    >
                </AccordionItem>
            </Accordion>


        </Card>
    </div>
</div>
