import { toast } from "@zerodevx/svelte-toast";

const BASE_URL = import.meta.env.VITE_BASE_URL;

async function fetchFromApi(endpoint, options = {}) {
    const response = await fetch(`${BASE_URL}${endpoint}`, options);
    if (!response.ok) {
        const cont = await response.text();
        toast.push(JSON.parse(cont).detail.message);
        return JSON.parse(cont);
    }
    return await response.json();
}

export async function fetchCompanyInfo(company) {
    return await fetchFromApi(`/search/${company}`);
}

export async function assessInnovation(details) {
    return await fetchFromApi(`/assess`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-control-allow-credentials': 'true',
            'vary': 'Origin'
        },
        body: JSON.stringify({
            details,
        })
    });
}



export async function earlyAccess(entry) {
    return await fetchFromApi(`/early_access`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-control-allow-credentials': 'true',
            'vary': 'Origin'
        },
        body: JSON.stringify({
            name: entry.name,
            email: entry.email,
            company_name: entry.company_name
        })
    });
}
export async function extract(files, module) {
    const formData = new FormData();
    Array.from(files).forEach(file => {
        formData.append("files", file);
    });
    return await fetchFromApi('/extract?' + new URLSearchParams({ module: module }).toString(), {
        method: 'POST',
        body: formData,
    });
}



export async function askAssistant(general_prompt, question_instruction, question, module) {
    return await fetchFromApi(`/ask_assistant`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-control-allow-credentials': 'true',
            'vary': 'Origin'
        },
        body: JSON.stringify({
            question,
            question_instruction,
            module,
            general_instruction: general_prompt
        })
    });
}


export async function fetchFiles(module) {
    return await fetchFromApi('/files?' + new URLSearchParams({ module: module }).toString(), {
        method: 'GET',
    });
}

export async function deleteFile(file_id, module) {
    return await fetchFromApi(`/files/${file_id}?module=${module}`, {
        method: 'DELETE',

    });
}

export async function getFileVectorizationProgress(file_id) {
    return await fetchFromApi(`/files/${file_id}/vectorization_progress`, {
        method: 'GET',
    });
}



export async function getSourceAttributes(content) {
    return await fetchFromApi(`/source_attributes`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            content
        })
    });
}
export async function getHallucinations(content) {
    return await fetchFromApi(`/find_discrepancies`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            content
        })
    });
}

export async function getUser() {
    return await fetchFromApi(`/me`, {
        method: 'GET',
        credentials: 'include',
    });
}

export async function logout() {
    const resp = await fetchFromApi(`/logout`, {
        method: 'POST',
        credentials: 'include',
    });
    return resp;
}

export async function login(formData) {
    const resp = await fetchFromApi(`/login`, {
        method: 'POST',
        credentials: 'include',
        body: formData
    });
    return resp;
}

export async function register(email, password) {
    const resp = await fetchFromApi(`/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email,
            password
        })
    });
    return resp;
}

export async function customerSupport(inquiry) {
    return await fetchFromApi(`/customer_support`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...inquiry
        })
    });
}

export async function get_upload_url(filename, module) {
    return await fetchFromApi(`/generate-upload-url`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            filename,
            module
        })
    });
}

export async function get_file_summary(file_id) {
    return await fetchFromApi(`/files/${file_id}/summary`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}


export async function download_file(text, doc_type, module, title) {

    let response = await fetch(`${BASE_URL}/download?module=${module}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            text,
            doc_type
        })
    });
    // Receive the file as a Blob
    const blob = await response.blob();

    // Create a downloadable link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const today = new Date();
    const formattedDate = today.getFullYear().toString() +
    (today.getMonth() + 1).toString().padStart(2, '0') +
    today.getDate().toString().padStart(2, '0');

    if (module === "draft") {
        link.setAttribute('download', `${formattedDate}_${title}_AIF Technical Narrative_Draft.${doc_type}`);  // Set the download attribute with the file name
    } else if (module === "research") {
        link.setAttribute('download', `${formattedDate}_Baseline Research.${doc_type}`);  // Set the download attribute with the file name
    }

    // Append the link to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);

}




export async function get_vector_store_files() {
    return await fetchFromApi(`/get_vector_stores`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}




export async function delete_vector_stores() {
    return await fetchFromApi(`/delete_vector_stores`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}



export async function get_result(result_id) {
    return await fetchFromApi(`/get_result/${result_id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}



export async function change_password_request(email) {

    return await fetchFromApi(`/change-password-request?email=${email}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}


export async function verify(email, code) {

    return await fetchFromApi(`/verify?email=${email}&code=${code}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}


export async function change_password(email, new_password, temporary_token) {

    return await fetchFromApi(`/change-password?email=${email}&new_password=${new_password}&temporary_token=${temporary_token}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}




export async function ask_llm(user_instruction, module, section, sub_section, selected_model) {
    return await fetchFromApi(`/ask_llm`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user_instruction,
            module,
            section,
            sub_section,
            selected_model
        })
    });
}
