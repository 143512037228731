<script>
  import { onMount, afterUpdate } from "svelte";
  import { createEventDispatcher } from "svelte";
  import { show_logs, terminal_messages } from "../service/store";
  import { Toggle } from "flowbite-svelte";
  import { TrashBinSolid } from "flowbite-svelte-icons";
  import { toast } from "@zerodevx/svelte-toast";

  let terminalEnd;
  let parsed_messages = [];

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (terminalEnd) {
      terminalEnd.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Scroll after the component is mounted
  onMount(() => {
    scrollToBottom();
  });

  // Scroll after every update (new messages)
  afterUpdate(() => {
    scrollToBottom();
  });

  function getFormattedDateTime() {
    const currentDateTime = new Date();
    return currentDateTime.toLocaleString("sv-SE").replace("T", " ");
  }

  $: parsed_messages = $terminal_messages.map((item) => (item));
</script>

<div class="flex justify-between mb-5">
  <Toggle bind:checked={$show_logs}>Show Logs</Toggle>
  <button
    on:click={() => {
      terminal_messages.set([]);
      toast.push("Logs cleared!");
    }}
    class="mr-5 text-gray-700 hover:text-gray-900"
    aria-label="Clear Logs"
  >
    <TrashBinSolid />
  </button>
</div>

{#if $show_logs}
  <div class="terminal">
    {#each parsed_messages as msg}
        <div class="message">
          <p class={msg.type} style="display: inline;">{msg.type}</p>
          <p class="timestamp" style="display: inline;">{msg.timestamp}</p>
          {JSON.stringify(msg.message)}
        </div>
    {/each}
    <div bind:this={terminalEnd}></div>
  </div>
{/if}

<style>
  /* Overall Container (optional if needed) */
  :global(body) {
    background-color: #f5f5f5;
    color: #333333;
  }

  /* Toggle Component (if customization needed) */
  /* Ensure the Toggle reflects light theme colors */

  /* Terminal Styling */
  .terminal {
    background-color: #ffffff; /* Light background */
    color: #333333; /* Dark text */
    font-family: "Courier New", Courier, monospace;
    font-size: small;
    padding: 1em;
    border: 1px solid #e5e5e5; /* Light border */
    border-radius: 5px;
    height: 150px; /* Adjust as needed */
    overflow-y: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Lighter shadow */
  }

  .message {
    margin: 0.2em 0;
    white-space: pre-wrap;
  }

  /* Timestamp Styling */
  .timestamp {
    color: #666666;
    margin-left: 0.5em;
  }

  /* Log Level Styling */
  .info {
    color: #1a73e8; /* Blue for info */
  }

  .warn {
    color: #ff9800; /* Orange for warnings */
  }

  .error  {
    color: #f44336; /* Red for errors */
  }
  .command {
    color: #f44336; /* Red for errors */
  }
  /* Button Styling */
  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5em;
    border-radius: 4px;
    transition: background-color 0.3s;
  }

  button:hover {
    background-color: #e0e0e0; /* Light hover effect */
  }

  /* Optional: Adjust Toggle component if needed */
  /* This depends on how Flowbite-Svelte Toggle handles themes */
</style>
