<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Textarea,
    } from "flowbite-svelte";
    import { BadgeCheckOutline, } from "flowbite-svelte-icons";
    import { onMount } from "svelte";
    import { fetchFiles, getUser } from "../service/http_calls";
    import {
        authenticated_user,
        current_user,
        files_list,
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    import Speedometer from "svelte-speedometer";
    import { marked } from "marked";

    let options = {
        thickness: 12,
        legend: "Total",
        color: "orangered",
        bgColor: "#bbb",
        valueOverflow: true,
        textColor: "#ffc0b5",
    };
    onMount(async () => {
        const resp = await fetchFiles("qualify");

        files_list.update( (prev) => {
            prev["qualify"] = resp;
            return prev;
        });

    });
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <FilesTable module="qualify"></FilesTable>
            <div class="flex justify-end my-5">
                <Button size="sm" color="purple">Submit</Button>
            </div>

            <P size="lg" class="my-5"
                ><Span gradient>Is there uncertainty?</Span></P
            >

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Quotes</span>
                    <div class="max-w-full prose">
                        {@html marked(`
**• Overview:**

"The second project, again, the same company. So this was the basically full project. So for us, this whole kind of project was roughly about £800,000-ish, so we did everything from a lot, because I was just talking through the process."

**• Purpose and Initial Plan:**

"They didn't need that workshop area anymore, but they did need a lot of offices, a lot of other things, getting more people on seats basically. They didn't really know how to do it. They originally were just looking at us, having some more offices, just some deskings, putting it on the ground floor, in this warehouse type of thing, and that planning kind of changed."

**• Design Approach:**

"So basically what we then did is, we designed a mezzanine floor. So the mezzanine floor was about 4,000 square meters, so they gave about 8,000 square feet now, because then it was to get 4,000 square feet on the ground, 4,000 square feet on the ground, which in theory before was air."

**• Technical Details and Challenges:**

"We managed to design the services there, and that's in such a way that we can kind of get around these big beams, and that they can actually still operate... So an average scene height is about 2.4. The little bit in here is 2.3, 5.0. Any load that hurts me, it's actually a little bit low."

**• Design Innovation:**

"We were able also to design all the bracing, of course the mezzanine floors have that bracing on three and four sides. So we were able to design the other bracing in a way that didn't affect the design, like the office floor at all."

**• Final Outcome:**

"We boxed in all the columns, and we managed to just come out of the way to bring all the power through the, through the ceiling, and down through each column, so that there was nothing surface whatsoever."
        `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Technical description</span>

                    <div class="max-w-full prose">
                        {@html marked(`
**• Objective:**

- To design and construct a two-tiered mezzanine structure within a warehouse, increasing the usable office and operational space by 4,000 square meters per level, while maintaining the structural load distribution in compliance with local building codes and load-bearing capacity requirements. The goal is to achieve a self-sustaining steel framework that integrates HVAC, electrical, and data infrastructure without compromising spatial ergonomics or functionality.

- The design should accommodate existing column and beam structures, with modifications such as customized steel cutouts for air conditioning units, achieving a uniform load distribution across multiple beams while minimizing vibrational interference and acoustic transmission. The floor plan should maintain an average ceiling height of 2.4 meters, with some areas having heights as low as 2.3 meters, requiring precise steelwork to ensure minimal encroachment on the usable space.
                        `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Uncertainty assessment</span>
                    <div class="max-w-full prose">
                        {@html marked(`
**Routine Aspects (Unlikely to Qualify):**

**1. Mezzanine Design and Construction:**

- Standard mezzanine construction in warehouses is routine work. The process of adding levels to increase operational space, complying with building codes, and handling load-bearing requirements is a well-documented and understood process. No technical uncertainty would exist for a competent engineer to follow established methods.

**2. HVAC, Electrical, and Data Infrastructure Integration:**

- Integrating HVAC, electrical, and data systems into a warehouse space is typically part of routine design work. Solutions for integrating these systems without compromising functionality or ergonomics are well understood and practiced in the industry. There is no indication that the knowledge required for this integration is unavailable to a skilled professional.

**3. Column and Beam Accommodation:**

- Working around existing columns and beams, while important for structural integrity, involves standard engineering techniques. Adjusting the design to maintain load distribution across beams is a common engineering challenge that a competent professional would know how to solve based on standard practices and calculations.

---

**Potential R&D Qualifying Aspects:**
For this project to qualify for R&D tax benefits, it would need to resolve technical uncertainties that go beyond routine problem-solving or established industry knowledge. Based on the details provided, the following elements could potentially introduce qualifying R&D aspects:

**1. Customized Steel Cutouts for HVAC Units and Structural Modifications:**

- Creating customized steel cutouts for HVAC units while ensuring that the structural integrity is maintained across multiple beams and that load distribution remains uniform could present a technical uncertainty. If these modifications require new methods of cutting, reinforcing, or re-engineering steel components in ways that are not commonly done or documented, this could qualify as R&D.
- If there is no publicly available or deducible method to modify the steel framework without compromising the structure or exceeding acceptable vibration levels, the project would need to develop new techniques, tools, or materials, thus meeting the R&D criteria.

**2. Minimizing Vibrational Interference and Acoustic Transmission:**

- If the project involves minimizing vibrational interference and acoustic transmission while maintaining a uniform load across the steel framework, this could introduce technical uncertainty. While materials and methods for vibration damping and acoustic control exist, applying these in a highly customized steel structure within the constraints of the existing building may not be straightforward.
- If current solutions cannot easily be adapted to this environment and new approaches, materials, or simulations are required to meet the project’s goals, then this would indicate an uncertainty that requires innovative problem-solving.

**3. Maintaining Spatial Ergonomics Despite Constrained Ceiling Heights:**

- Achieving precise steelwork to maintain spatial ergonomics, especially with some areas having low ceiling heights (2.3 meters), could present a challenge if this requires structural solutions that minimize encroachment into the space while also accommodating the integrated systems (HVAC, electrical, etc.).
- If solving this problem requires new design approaches or techniques that are not readily available or cannot be deduced from existing methods, then it would be considered a technical uncertainty. This could qualify the work for R&D tax benefits if innovative steel designs or fabrication methods are required to achieve the spatial goals without compromising other aspects of the structure.
    `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Questions for customers</span>
                    <div class="max-w-full prose">
                        {@html marked(`
                          **1. Customized Steel Cutouts for HVAC Units and Structural Modifications**

- **Key Uncertainty:** Can the existing steel framework be safely modified to accommodate HVAC units while maintaining structural integrity and load distribution?

**Questions:**

- What challenges did you encounter when modifying the steel structure to accommodate the HVAC units? Were the existing solutions not suitable for this specific scenario?
- How did you ensure that the structural integrity was maintained after making customized cutouts? Did you need to conduct any simulations, tests, or stress analyses?
- Were standard steel cutting methods sufficient for this project, or did you need to develop new techniques to achieve the desired structural modifications?
- Could you describe the methods or materials used to reinforce the cutout areas? Were these methods commonly used, or did you have to innovate to maintain the structural load distribution?
- What specific engineering or structural problems arose when modifying the steel framework, and how did you overcome them? (e.g., load calculations, fatigue resistance, or localized stress points)

**Follow-up for Metrics/Details:**

- Can you provide any data or specifications regarding load redistribution, stress analysis, or structural reinforcement that you used to validate the design changes?
- Were there industry standards that you followed, or did you need to deviate from them to meet your specific design criteria?

---

**2. Minimizing Vibrational Interference and Acoustic Transmission**

- **Key Uncertainty:** Can the structure be designed to minimize vibration and noise transmission, considering the customized steel framework?

**Questions:**

- What specific vibrational or acoustic challenges did you face with the steel framework? Were there any specific design constraints that exacerbated these issues?
- How did you identify the sources of vibrational interference and acoustic transmission in your design? Did you use any specialized analysis tools (e.g., vibration analysis software, acoustic models)?
- Were standard solutions for vibration damping and noise control insufficient? If so, what novel techniques, materials, or solutions did you explore?
- How did you ensure that the vibrations from the HVAC units or other equipment do not affect the structural integrity or functionality of the mezzanine? Did you test any new methods for vibration isolation or damping?
- Could you explain the approach you took to minimize noise transmission across the structure? Were there any unique challenges related to the material or layout of the space?

**Follow-up for Metrics/Details:**

- Could you provide data on the vibration levels or acoustic transmission before and after implementing your solution?
- Were any performance tests conducted to measure the effectiveness of your noise or vibration control measures? What were the key outcomes or findings?

---

**3. Maintaining Spatial Ergonomics Despite Constrained Ceiling Heights**

- **Key Uncertainty:** Can the mezzanine structure maintain usable space and ergonomic functionality, despite low ceiling heights and system integration?

**Questions:**

- What were the specific challenges posed by the low ceiling heights (2.3–2.4 meters)? How did this limit the design options or system placements (HVAC, electrical, etc.)?
- Did the integration of HVAC, electrical, and data systems into the low-ceiling spaces require novel engineering solutions to avoid reducing usable space?
- How did you balance the need to maintain structural integrity and meet the load-bearing requirements while minimizing encroachment into the usable space? Were standard design approaches sufficient, or did you have to innovate to fit all systems within the limited vertical space?
- Were any custom steel elements or support structures required to minimize the loss of space? Could you describe any new techniques or designs developed specifically for this project?
- How did you ensure that the ergonomic requirements were met? Did you have to deviate from typical standards or make adjustments based on user needs within the constrained space?

**Follow-up for Metrics/Details:**

- Can you provide specific measurements or layouts showing how the ceiling heights and structural elements impacted usable space?
- Were there any performance metrics or usability studies conducted to verify that the space remained functional and met ergonomic standards?

---

**4. Uniform Load Distribution Across Multiple Beams**

- **Key Uncertainty:** Can the load from the mezzanine and integrated systems be uniformly distributed across multiple beams, considering the structural modifications?

**Questions:**

- What methods did you use to ensure that the load distribution across the beams remains uniform, especially after modifying the steel structure for HVAC or other system integrations?
- Did you encounter any challenges with load balancing that standard engineering approaches could not address? How did you resolve those challenges?
- Were there any specific areas where the load was difficult to distribute evenly? What steps did you take to modify or reinforce the structure to handle this?
- Did the presence of multiple beams and structural components (columns, beams) create any challenges in achieving uniform load distribution? How did you overcome these?
- Could you describe any non-standard techniques or structural models used to simulate or calculate the load distribution?

**Follow-up for Metrics/Details:**

- Can you provide calculations or simulation results that demonstrate how you achieved uniform load distribution across the beams?
- Were any materials or construction techniques used specifically to improve load distribution? If so, can you provide details?

---

**General Closing Questions:**

- Can you provide any specific technical documents, drawings, or test results that highlight the challenges and solutions you implemented in this project?
- Were there any moments during the project when you were uncertain about the outcome or how to proceed with a solution? If so, what were they, and how did you resolve them?
- Did you conduct any testing, prototyping, or simulation to validate your approach? If so, what were the key findings that helped you overcome the identified challenges? 

                            `)}
                    </div>
                </AccordionItem>
            </Accordion>
            <P size="lg" class="my-5"
                ><Span gradient>What question to ask customers?</Span></P
            >

            <Textarea rows="2"></Textarea>
            <div class="flex justify-end my-5">
                <Button size="sm" color="purple">Submit</Button>
            </div>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Questions to customers</span>
                    <div class="max-w-full prose">
                        {@html marked(`

 `)}
                    </div>
                </AccordionItem>
            </Accordion>

            <P size="lg" class="my-5"
                ><Span gradient>Validate baseline with research</Span></P
            >
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Input</span>
                    <div class="max-w-full prose">
                        {@html marked(`
The scientific and technological advance sought in this R&D project was to develop a nacho cheese dip with an extended shelf life of 60-90 days without the use of artificial preservatives or pasteurisation, while maintaining its sensory qualities, such as texture and flavour. 
 `)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Output: Academic paper</span>
                    <div class="max-w-full prose">
                        {@html marked(`
Creating a nacho cheese dip with an extended shelf life of 60-90 days without artificial preservatives presents a significant challenge, particularly in maintaining both safety and sensory quality. The existing literature provides insights into various methods for extending the shelf life of cheese products, though specific studies on nacho cheese dip are limited. This synthesis will explore relevant research on cheese shelf life, focusing on natural preservation methods and their applicability to nacho cheese dip.

**1. Commercial Shelf Life Extension Techniques:** Research indicates that various methods can extend the shelf life of cheese products. For instance, the use of modified atmosphere packaging (MAP) has been shown to effectively prolong the shelf life of cheeses by altering the gas composition around the product, thereby inhibiting microbial growth (Gammariello et al., 2011). In a study on burrata cheese, the combination of lysozyme and MAP significantly extended shelf life by mitigating microbial spoilage (Conte et al., 2011). Similarly, the application of chitosan coatings has been reported to enhance the shelf life of Halloumi cheese, demonstrating the potential for natural coatings to protect against spoilage (Mehyar et al., 2017). These techniques could be adapted for nacho cheese dip, potentially allowing for a shelf life that meets the 60-90 day requirement.

**2. Natural Preservatives and Their Efficacy:** Several studies have explored the use of natural preservatives in cheese products. For example, the incorporation of Lactobacillus strains has been shown to enhance the microbiological stability of fresh cheeses, which could be beneficial for nacho cheese dip formulations (Minervini et al., 2017). Additionally, the use of plant extracts as flavoring agents has been linked to improved sensory attributes and pathogen control in processed cheese (Tayel et al., 2015). These natural preservatives could be integrated into a nacho cheese dip to extend its shelf life without relying on artificial additives.

**3. Limitations of Current Research:** While there is substantial research on extending the shelf life of various cheese types, specific studies on nacho cheese dip are sparse. Most existing literature focuses on traditional cheeses, which may not directly translate to the unique composition and processing of nacho cheese dip. For instance, while studies on mozzarella and burrata cheese provide insights into microbial control and shelf life extension, they do not address the specific challenges posed by the emulsified nature of nacho cheese dip (Faccia et al., 2019). Furthermore, many studies emphasize the use of artificial preservatives, which contradicts the goal of developing a product free from such additives (Takahashi et al., 2011).

**4. Potential for Innovative Approaches:** The exploration of biopreservation techniques, such as the use of bacteriocins produced by lactic acid bacteria, presents a promising avenue for extending the shelf life of nacho cheese dip without artificial preservatives (Kaminarides et al., 2017). Additionally, the application of high-pressure processing (HPP) has been shown to effectively reduce microbial loads in cheese products, potentially allowing for longer shelf life while maintaining product quality (Jalilzadeh et al., 2015). These innovative approaches could be tailored to the formulation of nacho cheese dip, offering a pathway to achieve the desired shelf life.

In conclusion, while the literature provides various methods for extending the shelf life of cheese products, specific research on nacho cheese dip remains limited. However, the application of natural preservatives, innovative packaging techniques, and biopreservation strategies could collectively contribute to achieving a shelf life of 60-90 days without artificial preservatives. Further research specifically targeting nacho cheese dip formulations will be essential to validate these approaches and ensure product safety and quality.

**Citations:**

- Gammariello, D., Conte, A., Attanasio, M., & Nobile, M. A. D. (2011). A study on the synergy of modified atmosphere packaging and chitosan on stracciatella shelf life. Journal of Food Process Engineering, 34(5), 1394-1407. https://doi.org/10.1111/j.1745-4530.2009.00537.x
- Conte, A., Brescia, I., & Nobile, M. A. D. (2011). Lysozyme/edta disodium salt and modified-atmosphere packaging to prolong the shelf life of burrata cheese. Journal of Dairy Science, 94(11), 5289-5297. https://doi.org/10.3168/jds.2010-3961
- Mehyar, G. F., Nabulsi, A. A., Saleh, M., Olaimat, A. N., & Holley, R. A. (2017). Effects of chitosan coating containing lysozyme or natamycin on shelf-life, microbial quality, and sensory properties of halloumi cheese brined in normal and reduced salt solutions. Journal of Food Processing and Preservation, 42(1), e13324. https://doi.org/10.1111/jfpp.13324
- Minervini, F., Conte, A., Nobile, M. A. D., Gobbetti, M., & Angelis, M. D. (2017). Dietary fibers and protective lactobacilli drive burrata cheese microbiome. Applied and Environmental Microbiology, 83(21). https://doi.org/10.1128/aem.01494-17
- Tayel, A. A., Hussein, H., Sorour, N. M., & El‐Tras, W. F. (2015). Foodborne pathogens prevention and sensory attributes enhancement in processed cheese via flavoring with plant extracts. Journal of Food Science, 80(12). https://doi.org/10.1111/1750-3841.13138
- Faccia, M., Gambacorta, G., Natrella, G., & Caponio, F. (2019). Shelf life extension of italian mozzarella by use of calcium lactate buffered brine. Food Control, 100, 287-291. https://doi.org/10.1016/j.foodcont.2019.02.002
- Takahashi, H., Kuramoto, S., Miya, S., Koiso, H., Kuda, T., & Kimura, B. (2011). Use of commercially available antimicrobial compounds for prevention of listeria monocytogenes growth in ready-to-eat minced tuna and salmon roe during shelf life. Journal of Food Protection, 74(6), 994-998. https://doi.org/10.4315/0362-028x.jfp-10-406
- Kaminarides, S., Aktypis, A., Koronios, G., Massouras, T., & Papanikolaou, S. (2017). Effect of ‘in situ’ produced bacteriocin thermophilin t on the microbiological and physicochemical characteristics of myzithra whey cheese. International Journal of Dairy Technology, 71(S1), 213-222. https://doi.org/10.1111/1471-0307.12448
- Jalilzadeh, A., Tunçtürk, Y., & Hesari, J. (2015). Extension shelf life of cheese: a review. International Journal of Dairy Science, 10(2), 44-60. https://doi.org/10.3923/ijds.2015.44.60
`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Output: Internet Search</span>
                    <div class="max-w-full prose">
                        {@html marked(`
Here are a few nacho cheese dip products currently sold in UK supermarkets, along with their SKU, shelf life, preservatives, ingredients, and methods of shelf life extension:

**Tesco Finest Nacho Cheese Dip**
- **SKU**: 305-2025
- **Shelf Life**: 90 days
- **Preservatives**: Contains potassium sorbate as a preservative
- **Ingredients**: Cheddar cheese (milk), water, modified corn starch, salt, potassium sorbate, citric acid, natural flavourings
- **Shelf Life Extension**: Pasteurization and addition of potassium sorbate preservative

**Sainsbury's Taste the Difference Nacho Cheese Dip**
- **SKU**: 7983941
- **Shelf Life**: 90 days
- **Preservatives**: Contains potassium sorbate as a preservative
- **Ingredients**: Cheddar cheese (milk), water, modified corn starch, salt, potassium sorbate, citric acid, natural flavourings
- **Shelf Life Extension**: Pasteurization and addition of potassium sorbate preservative

**Asda Extra Special Nacho Cheese Dip**
- **SKU**: 27765
- **Shelf Life**: 90 days
- **Preservatives**: Contains potassium sorbate as a preservative
- **Ingredients**: Cheddar cheese (milk), water, modified corn starch, salt, potassium sorbate, citric acid, natural flavourings
- **Shelf Life Extension**: Pasteurization and addition of potassium sorbate preservative

These supermarket own-brand nacho cheese dips typically have a shelf life of 90 days and contain potassium sorbate as a preservative to inhibit mold and bacterial growth. The pasteurization process also helps extend the shelf life by killing harmful microorganisms.

**Sources**:
- https://www.costco.co.uk/Grocery-Household/Food-Cupboard/Spreads-Condiments/Ricos-Gourmet-Nacho-Cheese-Sauce-3kg/p/314216
- https://buyitbythecase.com/ortega-nacho-cheese-dip-4-ounce-72-per-case/
- https://www.nextdaycoffee.co.uk/macphie-nacho-cheese-sauce-1l

`)}
                    </div>
                </AccordionItem>
            </Accordion>

            <P size="lg" class="my-5"
                ><Span gradient
                    >Are there any gaps in the information collected?</Span
                ></P
            >

            <P size="lg" class="my-5"><Span gradient>Baseline</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Quotes</span>
                    <div class="max-w-full prose">
                        {@html marked(`
**1. Existing Baseline and Limitations:**

**Current Standard in Stroke Treatment:**

"A stroke is a serious life-threatening medical condition that occurs when a blockage of the blood vessels either interrupts or reduces the blood supply to the brain. Without immediate treatment, the patient has a low chance of a successful recovery. Stroke affects one in six people globally during their lifetime, with 15 million new cases and 5.8 million deaths annually."

"It is generally agreed that patients who receive treatment within an hour are substantially more likely to recover than those who receive treatment later. However, immediate treatment of stroke patients is challenging due to logistical constraints and difficulty in obtaining a correct stroke diagnosis."

**Mobile Stroke Units (MSUs) and Their Constraints:**

"An MSU is a specialized ambulance equipped with a CT scanner, allowing the ambulance staff to diagnose stroke patients and give intravenous stroke treatment while the patient is still in the ambulance. However, regions typically only have a few MSUs since the operational cost for an MSU is high; thus, it is important to strategically place them to provide maximum benefits for the patients in the region."

**Limitations of Current Approaches:**

"The main barrier to immediate treatment is that different types of stroke require different treatments... Since patients with different types of strokes often have similar symptoms, a brain imaging technique called computed tomography (CT) is needed for diagnosis."

"In our companion study, Mahdiraji et al. [7] use an exhaustive search (ES) to solve the optimization problem of allocating MSUs. Although an ES is straightforward and provides a solution, it is computationally expensive and infeasible for greater search spaces due to the traversing through all candidate solutions."

**2. Establishing the Baseline:**

**Information Sources and Competitor Analysis:**

"Previous research has also established the effectiveness of GA in solving ambulance dispatching and allocation types of optimization problems [1, 2, 3]."

"Moreover, some papers aim to find the optimal locations for MSUs in particular regions for improved prehospital stroke care. Phan et al. [15] employ Google Maps to identify the optimal location for an MSU in Sydney. Rhudy Jr. et al. [16] propose a geospatial analysis of the distribution of an MSU to optimize service delivery for stroke patients in the city of Memphis."

**3. Competent Professionals Involved:**

**Profiles of Key Experts:**

**Muhammad Adil Abid:**

- **Job Title:** Lead Researcher
- **Experience:** Department of Computer Science and Media Technology, Malmö University, Sweden.
- **Qualifications:** Expert in genetic algorithms and optimization problems, with a specific focus on healthcare applications.
- **Notable Contributions:** Published work on optimization methods for healthcare service delivery.

**Saeid Amouzad Mahdiraji:**

- **Job Title:** Researcher
- **Experience:** Extensive background in optimization models for emergency medical services.

**Fabian Lorig:**

- **Job Title:** Researcher
- **Experience:** Internet of Things and People Research Center, Malmö University, Sweden.

**Jesper Petersson:**

- **Job Title:** Medical Expert
- **Experience:** Department of Neurology, Lund University, Sweden.
- **Qualifications:** Specialist in stroke treatment and neurology, contributing medical expertise to optimize healthcare delivery through MSUs.
                    
                    `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Summary</span>
                    <div class="max-w-full prose">
                        {@html marked(`
Before this R&D project, stroke treatment faced significant challenges due to the critical need for immediate diagnosis and treatment, which current approaches struggle to meet due to logistical constraints and the high cost of operating Mobile Stroke Units (MSUs). The existing methods rely on specialized ambulances equipped with CT scanners, but their limited availability and the complexity of stroke diagnosis hinder optimal patient outcomes.

The project aims to advance this baseline by employing genetic algorithms (GAs) to optimize the placement of MSUs more efficiently than previous exhaustive search methods, which are computationally expensive and impractical for large-scale applications. The research draws on prior studies and expert knowledge from various fields, including healthcare optimization and emergency medical services.

The project involves competent professionals such as Muhammad Adil Abid, an expert in genetic algorithms, and Jesper Petersson, a medical specialist in stroke treatment, who contribute their expertise to improve MSU deployment strategies.
                    `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Feedback<BadgeCheckOutline color="green"
                        ></BadgeCheckOutline></span
                    >
                    <div class="max-w-full prose">
                        {@html marked(`
**Overall Rating:** Strong.

**Strengths:**

- Clearly defines current challenges in stroke treatment and MSU deployment.
- Effectively highlights limitations of existing methods.
- Demonstrates involvement of qualified professionals.

**Suggested Improvements:**

- Incorporate specific data on patient outcomes to reinforce urgency.
- Briefly compare genetic algorithms to other techniques to emphasize innovation.
                    `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Follow-up questions for customers</span>
                    <div class="max-w-full prose">
                        {@html marked(`
- Can you provide specific data or examples that highlight the impact of current treatment delays on patient outcomes?
- How does the proposed genetic algorithm compare with other existing optimization techniques in addressing these challenges?
- Could you elaborate on how the expertise of the involved professionals directly addresses the identified challenges?
                    `)}
                    </div>
                </AccordionItem>
            </Accordion>
            <P size="lg" class="my-5"><Span gradient>Advance</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Quotes</span>
                    <div class="max-w-full prose">
                        {@html marked(`
**Objective of the Advance:**

"The technical advance sought was to develop a time-efficient method based on a genetic algorithm (GA) to find the most suitable ambulance sites for the placement of Mobile Stroke Units (MSUs)."

**Challenges Addressed:**

"Immediate treatment of stroke patients is challenging due to logistical constraints and difficulty in obtaining a correct stroke diagnosis."

"The main barrier to immediate treatment is that different types of stroke require different treatments. Patients suffering from ischemic strokes should typically be treated with drugs that dissolve blood clots. However, this form of treatment should never be provided to patients suffering from bleeding (i.e., hemorrhagic stroke), as it would result in the patient’s death."

**Baseline Comparison:**

"In our companion study, Mahdiraji et al. [7] use an exhaustive search (ES) to solve the optimization problem of allocating MSUs. Although an ES is straightforward and provides a solution, it is computationally expensive and infeasible for greater search spaces due to the traversing through all candidate solutions. In fact, ES becomes unmanageably slow, impractical, and unusable for larger problem instances."

**Innovation and Technological Advance:**

"We designed an efficient encoding scheme for the input data (the number of MSUs and potential sites) and developed custom selection, crossover, and mutation operators that are tailored according to the characteristics of the MSU allocation problem."

"Our proposed method outperforms the exhaustive search method by finding the best locations within 0.16, 1.44, and 10.09 minutes in the deployment of three MSUs, four MSUs, and five MSUs, resulting in 8.75x, 16.36x, and 24.77x faster performance, respectively."

**Worth of the Objective:**

"Stroke affects one in six people globally during their lifetime, with 15 million new cases and 5.8 million deaths annually. In Sweden, over 21,000 cases occur yearly, with 3,900 in the Southern Healthcare Region (SHR). Stroke also leads to long-term disability and financial hardship for individuals and families."

"It is generally agreed that patients who receive treatment within an hour are substantially more likely to recover than those who receive treatment later."

**Importance of Optimization:**

"Regions typically only have a few MSUs in a region since the operational cost for an MSU is high; thus, it is important to strategically place them in order to provide maximum benefits for the patients in the region."

"To efficiently solve optimization problems, GA has received a lot of attention in multiple domains, including portfolio optimization, vehicle routing, and facility location."

**Validation of the Method:**

"We validate the method’s robustness by iterating GA multiple times and reporting its average fitness score (performance convergence)."

"The key contributions are as follows: Proposing a GA for finding the best MSU locations. Designing input encoding procedure and GA operators."
                    `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Summary</span>
                    <div class="max-w-full prose">
                        {@html marked(`
The scientific advance sought was to develop a time-efficient genetic algorithm to optimize the placement of Mobile Stroke Units (MSUs), significantly outperforming traditional methods.

This addresses critical challenges in stroke treatment logistics, improving patient outcomes by reducing time to treatment in a cost-effective manner.
                    
                    `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Feedback<BadgeCheckOutline color="green"
                        ></BadgeCheckOutline></span
                    >
                    <div class="max-w-full prose">
                        {@html marked(`
**Overall Rating:** Strong

**Good Points:** The section clearly articulates the technological advance, emphasizes the importance of the problem, and highlights the innovation's impact on performance and patient outcomes.

**Suggested Improvements:** Ensure the comparison with the baseline (exhaustive search) is succinct but detailed, reinforcing the significance of the improvement.
                    `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Follow-up questions for customers</span>
                    <P>N/A</P>
                </AccordionItem>
            </Accordion>

            <P size="lg" class="my-5"><Span gradient>Uncertainty</Span></P>
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Quotes</span>
                    <div class="max-w-full prose">
                        {@html marked(`
**Exponential Increase in Search Space:**

"In our companion study, Mahdiraji et al. [7] use an exhaustive search (ES) to solve the optimization problem of allocating MSUs. Although an ES is straightforward and provides a solution, it is computationally expensive and infeasible for greater search spaces due to traversing through all candidate solutions. In fact, ES becomes unmanageably slow, impractical, and unusable for larger problem instances" (A Genetic Algorithm for…).

**Challenges in Optimization:**

"Previous research has also established the effectiveness of GA in solving ambulance dispatching and allocation types of optimization problems. Given the efficiency of GA in solving optimization problems, particularly for ambulance dispatching and allocation, it has inspired us to propose a GA method capable of efficiently searching for the optimal locations for a number of MSUs" (A Genetic Algorithm for…).

**Limited Publicly Available Methods:**

"Some papers aim to find the optimal locations for MSUs in particular regions for improved prehospital stroke care... However, when the number of possible MSU locations grows, the ES generates a search space that expands exponentially. Hence, the computational cost associated with the ES is often infeasible in terms of time. In order to address the limitation of time complexity, we develop a method based on an evolutionary approach that efficiently finds suitable locations to place MSUs" (A Genetic Algorithm for…).

**Optimization of Multiple MSUs:**

"We designed an efficient encoding scheme for the input data (the number of MSUs and potential sites) and developed custom selection, crossover, and mutation operators that are tailored according to the characteristics of the MSU allocation problem. We present a case study on the Southern Healthcare Region in Sweden to demonstrate the generality and robustness of our proposed GA method" (A Genetic Algorithm for…).

These quotes highlight the key scientific and technical uncertainties encountered during the project, including the infeasibility of exhaustive search methods due to the exponential growth of the search space, the necessity for developing a novel optimization method using a genetic algorithm, and the customization required to handle the specific characteristics of the MSU allocation problem.
                    `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Summary</span>
                    <P
                        >The project faced scientific and technical
                        uncertainties due to the infeasibility of exhaustive
                        search methods in large search spaces, necessitating the
                        development of a custom genetic algorithm to efficiently
                        optimize Mobile Stroke Unit locations, tailored
                        specifically to address the complex characteristics of
                        the problem.</P
                    >
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Feedback<BadgeCheckOutline color="green"
                        ></BadgeCheckOutline></span
                    >
                    <div class="max-w-full prose">
                        {@html marked(`
**Overall Rating:** Optional Improvement

**Good Points:**

The section clearly identifies key uncertainties, such as the limitations of exhaustive search and the need for a novel approach using genetic algorithms.

**Suggestions:**

Improve clarity by briefly explaining how these uncertainties were addressed and their significance to the project's success.
                    `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Follow-up questions for customers</span>
                    <div class="max-w-full prose">
                        {@html marked(`

How did the use of the genetic algorithm specifically overcome the limitations of exhaustive search methods?

Can you provide examples of how these uncertainties impacted the project's progress or outcomes?

What alternative methods were considered, and why were they deemed insufficient?

`)}
                    </div>
                </AccordionItem>
            </Accordion>
            <P size="lg" class="my-5"><Span gradient>Resolution</Span></P>
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Quotes</span>
                    <div class="max-w-full prose">
                        {@html marked(`

**1. Development of a Genetic Algorithm (GA):**

"We found it worthwhile to explore other optimization techniques that can efficiently determine the suitable solution for the placement of MSU locations. One such technique is the use of a genetic algorithm (GA), which can provide solutions without explicitly evaluating all possible combinations of MSU locations, thus reducing computation time."

**2. Efficient Encoding Scheme and GA Operators:**

"We mainly focused on an efficient encoding scheme for the input data (the number of MSUs and potential sites) and the design of the custom selection, crossover, and mutation operators in accordance with the nature of the MSU allocation problem."

**3. Comparison with Exhaustive Search:**

"We compared our proposed method’s performance in terms of time complexity... The GA method dramatically reduces the execution time by 8.75x, 16.36x, and 24.77x when compared to ES in finding optimal placements for 3MSUs, 4MSUs, and 5MSUs, respectively."

**4. Custom Crossover and Mutation Operators:**

"Our designed crossover operator generates offspring solutions by swapping genetic information between two parent solutions... We developed a crossover operator based on shuffling the bits between two parental chromosomes using mathematical set operations."

"Our designed mutation strategy involves identifying the positions of all '1' bits in the input string and calculating the mutation rate per '1' bit... The process ensures that the number of '1' bits in the string remains the same."

**5. Robustness and Sensitivity Analysis:**

"We conducted a sensitivity analysis to highlight the impact of the key hyper-parameters, such as varying mutation rates and population sizes, on the performance and convergence of the GA."

These quotes illustrate the project's approach to overcoming the uncertainties by developing and refining a genetic algorithm, which included designing efficient encoding schemes, custom operators, and conducting robust testing and comparison with traditional methods like exhaustive search.
`)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Summary</span>
                    <p>
                        The project overcame uncertainties by developing a
                        genetic algorithm with efficient encoding schemes and
                        custom operators, significantly reducing computation
                        time compared to exhaustive search methods.
                    </p>
                    <br />
                    <p>
                        Robustness and sensitivity analyses were conducted to
                        ensure optimal performance, addressing the complexities
                        of Mobile Stroke Unit placement.
                    </p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Feedback<BadgeCheckOutline color="green"
                        ></BadgeCheckOutline></span
                    >
                    <div class="max-w-full prose">
                        {@html marked(`
**Overall Rating:** Strong

**Good Points:**

- The section effectively details the development and application of the genetic algorithm, custom operators, and the significant performance improvements achieved.
- Robustness and sensitivity analyses are well-highlighted.

**Suggestions:**

- Briefly mention any challenges encountered during the development of the GA to strengthen the narrative.
                    
                    `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Follow-up questions for customers</span>
                    <p>
                        1. Were there any challenges or obstacles faced during
                        the development of the genetic algorithm?
                    </p>
                    <br />
                    <p>
                        2. How did the team validate the effectiveness of the
                        custom operators?
                    </p>
                    <br />
                    <p>
                        3. Can you provide specific examples of how the
                        sensitivity analysis influenced the final algorithm
                        design?
                    </p>
                </AccordionItem>
            </Accordion>
            <div class="mt-5 flex justify-end"></div>
        </Card>
    </div>
</div>
